import React, { useEffect, useState } from "react";
import { FormControl, MenuItem, Select } from "@mui/material";
import { useTranslation } from "react-i18next";

function StatusSelect({
  type = "task", // Default type
  value, // Allow controlled component
  onChange,
  isHighlighted,
  ...props
}) {
  const [selected, setSelected] = useState(value);
  const { t } = useTranslation("global");

  // Options for different types
  const importDeviceOptions = [
    "css",
    "ZtdDeviceAlreadyAssigned",
    "ZtdDeviceAssignedToOtherTenant",
    "cwe",
    "wip",
    "unk",
    "new",
  ];
  const taskOptions = [
    "css",
    "cwe",
    "cww",
    "cer",
    "cwr",
    "wip",
    "unk",
    "new",
    "rtd",
  ];

  const options = type === "importDevice" ? importDeviceOptions : taskOptions;

  // Handle changes and support both controlled/uncontrolled modes
  const handleChange = (event) => {
    const newValue = event.target.value;
    setSelected(newValue);
    onChange && onChange(newValue);
  };

  // Sync internal state if `value` changes (controlled mode)
  useEffect(() => {
    if (value !== undefined) {
      setSelected(value);
    }
  }, [value]);

  return (
    <FormControl size="small" fullWidth sx={{
      "& .MuiOutlinedInput-root": {
        border: isHighlighted ? "2px solid #777777" : "1px solid #ccc",
        // transition: "border 0.3s",
        // "&:hover": {
        //   border: "2px solid #ffa070",
        // },
      },
    }}>
      <Select
        {...props}
        value={selected} // Use internal state or controlled value
        onChange={handleChange}
      >
        {options.map((option) => (
          <MenuItem key={option} value={option}>
            {t(`state.${option}`,t(`properties.${option}`,option))}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default StatusSelect;
