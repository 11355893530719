import axios from "axios";
import { useCallback, useContext } from "react";
import { PartnerContext } from "../tools/PartnerContext";
import { Providers } from "@microsoft/mgt-element";

export const useAuthenticateApi = () => {
  const { setTenantId, setApiToken, setAuthenticated, setAuthorized } =
    useContext(PartnerContext);
  const authenticateApi = useCallback(
    async (info) => {
      const authorized = process.env.REACT_APP_AUTHORIZED_PARTNERS.split(',')
      Providers.globalProvider.getAccessToken().then((token) => {
        info.msToken = token;
        axios
          .post(`${process.env.REACT_APP_API_ENDPOINT}/authenticate/`, info)
          .then((response) => {
            setTenantId(response.data.tenantId);
            setApiToken(response.data.token);
            setAuthenticated(true);
            if (
              authorized.includes(
                response.data.tenantId
              )
            ) {
              setAuthorized(true);
            } else {
              setAuthorized(false);
            }
          })
          .catch(() => {
            setAuthenticated(false);
          });
      });
    },
    [setApiToken, setAuthenticated, setAuthorized, setTenantId]
  );
  return { authenticateApi };
};
export const useApi = () => {
  const { apiToken } = useContext(PartnerContext);
  const postApi = useCallback(
    async (uri, data) => {
      const headers = {
        Authorization: `Token ${apiToken}`,
      };
      return axios
        .post(`${process.env.REACT_APP_API_ENDPOINT}/${uri}`, data, {
          headers: headers,
        })
        .then((response) => response)
        .catch((error) => {
          throw error;
        });
    },
    [apiToken]
  );

  const getApi = useCallback(
    async (uri, signal = false) => {
      const headers = {
        Authorization: `Token ${apiToken}`,
      };
      if (signal) {
        return axios
          .get(`${process.env.REACT_APP_API_ENDPOINT}${uri}`, {
            headers: headers,
            signal: signal,
          })
          .then((response) => response)
          .catch((error) => {
            if (error.code === "ERR_CANCELED") {
              throw error;
            } else {
              console.log(error);
            }
          });
      } else {
        return axios
          .get(`${process.env.REACT_APP_API_ENDPOINT}${uri}`, {
            headers: headers,
          })
          .then((response) => response)
          .catch((error) => {
            throw error;
          });
      }
    },
    [apiToken]
  );
  return { postApi, getApi };
};
