import { createTheme } from "@mui/material/styles";
export const partnersTheme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#ff6600",
    },
    secondary: {
      main: "#474443",
    },
  },
  typography: {
    fontFamily: "Poppins",
  },
  overrides: {
    MuiAppBar: {
      colorInherit: {
        backgroundColor: "#689f38",
        color: "#fff",
      },
    },
  },
  props: {
    MuiTooltip: {
      arrow: true,
    },
  },
});
