import React, { useState } from "react";
import {
  AddToQueue,
  Home,
  Laptop,
  ExpandLess,
  ExpandMore,
} from "@mui/icons-material";
import {
  Box,
  Drawer,
  Typography,
  ListItemButton,
  ListItemText,
  Collapse,
  ListItemIcon,
  useMediaQuery,
  List,
} from "@mui/material";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function Sidebar({ isOpen, toggleDrawer }) {
  const { t } = useTranslation("global");
  const [deviceIsOpen, setDeviceIsOpen] = useState(true);
  const toggleDevice = () => setDeviceIsOpen(!deviceIsOpen);

  const menuOptions = [
    { name: "sidebar.dashboard", link: "/", Logo: Home },
    {
      name: "pages.device.title",
      link: "#",
      Logo: Laptop,
      collapsible: true,
      open: deviceIsOpen,
      setOpen: toggleDevice,
      items: [
        {
          name: "sidebar.import_request",
          link: "/device/import/",
          Logo: AddToQueue,
        },
      ],
    },
  ];

  const isLargeScreen = useMediaQuery((theme) => theme.breakpoints.up("lg"));

  const renderMenuOption = (info) => {
    if (info.collapsible) {
      return (
        <React.Fragment key={info.name}>
          <ListItemButton onClick={info.setOpen}>
            <ListItemIcon>
              <info.Logo />
            </ListItemIcon>
            <ListItemText primary={t(info.name)} />
            {info.open ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={info.open} unmountOnExit>
            <List>
              {info.items.map((sub) => (
                <ListItemButton
                  component={Link}
                  to={sub.link}
                  sx={{ pl: 4 }}
                  key={sub.name}
                >
                  <ListItemIcon>
                    <sub.Logo />
                  </ListItemIcon>
                  <ListItemText primary={t(sub.name)} />
                </ListItemButton>
              ))}
            </List>
          </Collapse>
        </React.Fragment>
      );
    }

    return (
      <ListItemButton component={Link} to={info.link} key={info.name}>
        <ListItemIcon>
          <info.Logo />
        </ListItemIcon>
        <ListItemText primary={t(info.name)} />
      </ListItemButton>
    );
  };

  return (
    <Drawer
      anchor="left"
      variant={isLargeScreen ? "persistent" : "temporary"}
      open={isLargeScreen || isOpen}
      onClose={toggleDrawer(false)}
      sx={{
        "& .MuiDrawer-paper": {
          width: 300,
          boxSizing: "border-box",
          position: isLargeScreen ? "relative" : "fixed",
        },
      }}
    >
      <Box
        sx={{ margin: 2 }}
        role="presentation"
        onClick={!isLargeScreen ? toggleDrawer(false) : undefined}
        onKeyDown={!isLargeScreen ? toggleDrawer(false) : undefined}
      >
        <Typography variant="h6">Toolbar Options</Typography>
        <List>
          {menuOptions.map((info) => renderMenuOption(info))}
        </List>
      </Box>
    </Drawer>
  );
}

export default Sidebar;