import React from "react";
import Page, { PageContent, PageTitle } from "../../components/layout/Page";
import { useTranslation } from "react-i18next";
import ImportDeviceRequestList from "../../components/device/ImportDeviceRequestList";

function ImportRequest() {
  const { t } = useTranslation("global");
  return (
    <Page>
      <PageTitle>{t("pages.importdevicerequest.title")}</PageTitle>
      <PageContent>
        <ImportDeviceRequestList />
      </PageContent>
    </Page>
  );
}

export default ImportRequest;
