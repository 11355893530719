import { Providers, ProviderState } from "@microsoft/mgt-element";
import { useContext } from "react";
import { useAuthenticateApi } from "./useApi";
import { PartnerContext } from "../tools/PartnerContext";

export const useAuthentication = () => {
  const { authenticated, setAuthenticated, authorized } =
    useContext(PartnerContext);
  const { authenticateApi } = useAuthenticateApi();

  Providers.globalProvider.onStateChanged(() => {
    if (Providers.globalProvider.state === ProviderState.SignedIn) {
      setAuthenticated(true);
      let info = { username: "", password: "" };
      Providers.me()
        .then((i) => {
          info.username = i.userPrincipalName;
          info.password = i.id;
        })
        .then(() => {
          authenticateApi(info);
        });
    }
  });

  return { isAuthenticated: authenticated, isAuthorized: authorized };
};
