import { createContext } from "react";

export const PartnerContext = createContext({
  authenticated: false,
  setAuthenticated: () => {},
  authorized: null,
  setAuthorized: () => {},
  tenantId: null,
  setTenantId: () => {},
  apiToken: null,
  setApiToken: () => {},
  refresh: 0,
  makeRefresh: () => {},
});
