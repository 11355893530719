import { Box, Grid2, Typography } from "@mui/material";
import React, { useContext } from "react";
import { ReactComponent as LoginLogo } from "../images/loginMicrosoft.svg";
import { Providers } from "@microsoft/mgt-element";
import { PartnerContext } from "../tools/PartnerContext";
function Login() {
  const NumiatoLogo = require("../images/logo.png");
  const loginImage = require("../images/login.png");
  const { setAuthenticated } = useContext(PartnerContext);
  return (
    <Grid2
      container
      component="main"
      sx={{
        height: "100vh",
        backgroundColor: (t) =>
          t.palette.mode === "light" ? t.palette.grey[50] : t.palette.grey[900],
      }}
    >
      <Grid2 size={{ xs: false, sm: 8, md: 6 }}>
        <Box
          sx={{
            my: { xs: 40, md: 10 },
            position: "fixed",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center-top",
            alignItems: "center",
            height: "100%",
            width: { xs: "100%", md: "50%" },
          }}
        >
          <Box sx={{ m: 3 }}>
            <img src={NumiatoLogo} alt="numiatoLogo" style={{ width: 350 }} />
          </Box>
          <Typography component="h1" variant="h3" sx={{ m: 3 }}>
            Numiato Partners
          </Typography>
          <Box
            onClick={() => {
              setAuthenticated("loading");
              Providers.globalProvider.login();
            }}
          >
            <LoginLogo />
          </Box>
        </Box>
      </Grid2>
      <Box
        component="img"
        src={loginImage}
        alt="login-image"
        sx={{
          position: "fixed",
          bottom: 0,
          right: 16,
          width: { xs: 300, sm: 500, md: 700, lg: 900 }, // Adjust width as needed
          height: "auto", // Maintain aspect ratio
          zIndex: 1000, // Ensure it appears above other content if necessary
        }}
      />
    </Grid2>
  );
}

export default Login;
