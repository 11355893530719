import { ThemeProvider } from "@emotion/react";
import "./App.css";
import "@fontsource/poppins";
import { partnersTheme } from "./tools/theme";
import Main from "./pages/Main";
import { PartnerContext } from "./tools/PartnerContext";
import { useInitializeContext } from "./hooks/useInitializeContext";
function App() {
  return (
    <ThemeProvider theme={partnersTheme}>
      <PartnerContext.Provider value={useInitializeContext()}>
       <Main /> 
      </PartnerContext.Provider>
    </ThemeProvider>
  );
}

export default App;
