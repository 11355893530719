import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
import { I18nextProvider } from "react-i18next";
import i18n from "./hooks/useI18n";
import { Providers } from "@microsoft/mgt-element";
import { Msal2Provider } from "@microsoft/mgt-msal2-provider";
import { NotificationProvider } from "./components/notification/NotificationStack";

Providers.globalProvider = new Msal2Provider({
  clientId: process.env.REACT_APP_CLIENT_ID,
  authority: "https://login.microsoftonline.com/organizations",
  redirectUri: process.env.REACT_APP_REDIRECT_URI,
  scopes: [
    "offline_access",
    "openid",
    "User.Read",
    "profile",
  ],
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <I18nextProvider i18n={i18n}>
        <NotificationProvider>
          <App />
        </NotificationProvider>
      </I18nextProvider>
    </BrowserRouter>
  </React.StrictMode>
);
