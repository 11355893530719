import React from "react";
import { useTranslation } from "react-i18next";
import Page, { PageContent } from "../components/layout/Page";
import { Box, Typography } from "@mui/material";
const welcomeImage = require("../images/welcome.png");

function Home() {
  const { t } = useTranslation("global");
  return (
    <Page>
      <PageContent>
        <Box
          sx={{
            display: "flex",
            flex: 1,
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Typography variant="h3">{t("pages.home.partner.title")}</Typography>
          <Box
            component="img"
            src={welcomeImage}
            alt="welcome-image"
            sx={{
              position: "fixed",
              bottom: 30,
              right: 50,
              width: { xs: 200, sm: 300, md: 400, lg: 600 }, // Adjust width as needed
              height: "auto", // Maintain aspect ratio
              zIndex: 1000, // Ensure it appears above other content if necessary
            }}
          />
        </Box>
      </PageContent>
    </Page>
  );
}

export default Home;
