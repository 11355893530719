import { useCallback, useContext, useEffect, useState } from "react";
import { useApi } from "./useApi";
import { useNotification } from "../components/notification/NotificationStack";
import { useTranslation } from "react-i18next";
import { PartnerContext } from "../tools/PartnerContext";
export const useImportRequest = () => {
  const { postApi } = useApi();
  const { makeRefresh } = useContext(PartnerContext);
  const { t } = useTranslation("global");
  const { sendNotification } = useNotification();
  const saveChanges = useCallback(
    (info) => {
      const body = { changes: info };
      postApi(`device/partner/importdevicerequest/update/`, body)
        .then(() => {
          sendNotification(t("partner.notification.request_accepted"));
          makeRefresh();
          return true;
        })
        .catch((r) => {
          sendNotification(r.message);
          return false;
        });
    },
    [makeRefresh, postApi, sendNotification, t]
  );

  return { saveChanges };
};

export const useImportRequestList = () => {
  const { getApi } = useApi();
  const { refresh } = useContext(PartnerContext);
  const [importRequestList, setImportRequestList] = useState(false);
  const getImportRequestList = useCallback(() => {
    getApi(`/device/partner/importdevicerequest/`)
      .then((response) => setImportRequestList(response.data))
      .catch((e) => console.log(e));
  }, [getApi]);
  useEffect(() => {
    getImportRequestList();
  }, [getImportRequestList, refresh]);
  return { importRequestList };
};
