import React from "react";
// import { secondaryColorDefaultProps } from "./helpers";
// import { withSharedProps } from './withSharedProps';
import "./loading.css";
const defaultProps = {
  color: "#38ad48",
  enabled: true,
  size: 50,
  style: {},
};
const secondaryColorDefaultProps = {
  ...defaultProps,
  secondaryColor: "rgba(0,255,255,0.44)",
};


const coords = [
  { x: 3, y: 48 },
  { x: 18, y: 33 },
  { x: 18, y: 48 },
  { x: 18, y: 63 },
  { x: 33, y: 48 },
  { x: 33, y: 18 },
  { x: 33, y: 33 },
  { x: 33, y: 63 },
  { x: 33, y: 78 },
  { x: 48, y: 3 },
  { x: 48, y: 18 },
  { x: 48, y: 33 },
  { x: 48, y: 48 },
  { x: 48, y: 63 },
  { x: 48, y: 78 },
  { x: 48, y: 93 },
  { x: 63, y: 18 },
  { x: 63, y: 33 },
  { x: 63, y: 48 },
  { x: 63, y: 63 },
  { x: 63, y: 78 },
  { x: 78, y: 33 },
  { x: 78, y: 48 },
  { x: 78, y: 63 },
  { x: 93, y: 48 },
];
const withSharedProps = (Component) => {
  function Wrapper(props) {
    const {
      color = defaultProps.color,
      enabled = defaultProps.enabled,
      size = defaultProps.size,
      style = defaultProps.style,
      ...otherProps
    } = props;

    // Merge shared and component-specific styles
    const componentProps = {
      ...otherProps,
      style: {
        color,
        overflow: "visible",
        width: normalizeSize(size),
        ...style,
      },
    };

    // Render the component only if `enabled` is true
    if (!enabled) return null;

    return <Component {...componentProps} />;
  }

  return Wrapper;
};
// Normalize the size to ensure it's a valid CSS value (e.g., "50px" if it's a number)
const normalizeSize = (size) => {
  const sizeStr = size.toString();
  return parseFloat(sizeStr).toString() === sizeStr ? `${size}px` : sizeStr;
};
function Component({
  secondaryColor = secondaryColorDefaultProps.secondaryColor,
  speed = secondaryColorDefaultProps.speed,
  still = secondaryColorDefaultProps.still,
  thickness = secondaryColorDefaultProps.thickness,
  ...svgProps
}) {
  const diamondStyle = {
    animation: `spinners-react-diamond ${140 / speed}s steps(2, end) infinite`,
  };

  if (still) diamondStyle.animation = "none";

  return (
    <svg fill="currentColor" {...svgProps} viewBox="0 0 96 96">
      {coords.map((c) => (
        <circle
          key={`${c.x}-${c.y}`}
          cx={c.x}
          cy={c.y}
          fill={secondaryColor}
          r={2.5 * (thickness / 100)}
        />
      ))}
      <g style={diamondStyle}>
        {coords.slice(0, 5).map((c) => (
          <circle
            key={`h-${c.x}-${c.y}`}
            cx={c.x}
            cy={c.y}
            r={3.5 * (thickness / 100)}
          />
        ))}
      </g>
    </svg>
  );
}

export const SpinnerRomb = withSharedProps(Component);
export const SpinnerDiamond = withSharedProps(Component);
