import { useState } from "react";
import { Providers, ProviderState } from "@microsoft/mgt-element";

export const useInitializeContext = () => {
  const [authenticated, setAuthenticated] = useState(
    Providers.globalProvider.state === ProviderState.SignedIn
  );
  const [authorized,setAuthorized] = useState(null)
  const [tenantId, setTenantId] = useState(null);
  const [apiToken, setApiToken] = useState(null);
  const [refresh, setRefresh] = useState(0)
  const makeRefresh=()=>{setRefresh(p=>p+1)}
  return {
    authenticated,
    setAuthenticated,
    tenantId,
    setTenantId,
    apiToken,
    setApiToken,
    authorized,
    setAuthorized,
    refresh,
    makeRefresh,
  };
};
