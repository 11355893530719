import { Box, Button, Typography } from "@mui/material";
import React, { useContext } from "react";
import { Providers } from "@microsoft/mgt-element";
import { useTranslation } from "react-i18next";
import { PartnerContext } from '../tools/PartnerContext';
const noAccessImage = require("../images/NoAccess.png");

function NotAuthorized() {
  const {t} = useTranslation("global")
  const { setAuthenticated } = useContext(PartnerContext);
  const handleSignOut = ()=>{
      sessionStorage.clear();
      setAuthenticated(false);
      Providers.globalProvider.logout();
  }
  return (
      <Box
        sx={{
          width: "100%",
          height: "100%",
          justifyContent: "center",
          // alignItems: "center",
          display: "flex",
          gap: "30px",
          flexDirection: "column",
        }}
      >
        <Typography variant="h3" sx={{display:"flex",marginTop:30,marginX:10}}>{t("pages.authentication.not_authorized.title")}</Typography>
        <Typography variant="h5" sx={{display:"flex",marginY:3,marginX:10}}>{t("pages.authentication.not_authorized.description")}</Typography>
        <Box sx={{display:"flex",marginX:15,gap:5}}>
        <Button variant="contained" href="mailto:support@numiato.de">{t("pages.policy.onboard.button.contact")}</Button>
        <Button variant="contained" onClick={handleSignOut}>{t("Navbar.logout")}</Button>

        </Box>
        <Box
          component="img"
          src={noAccessImage}
          alt="no-access"
          sx={{
            position: "fixed",
            bottom: 30,
            right: 50,
            width: { xs: 200, sm: 300, md: 400, lg: 600 }, // Adjust width as needed
            height: "auto", // Maintain aspect ratio
            zIndex: 1000, // Ensure it appears above other content if necessary
          }}
        />
      </Box>
  );
}

export default NotAuthorized;
