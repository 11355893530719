import React from "react";
import { useAuthentication } from "../hooks/useAuthentication";
import Login from "./Login";
import { MainPage,MainPageContent } from "../components/layout/Page";
import Navbar from "../components/layout/Navbar";
import Sidebar from "../components/layout/Sidebar";
import { Route, Routes } from "react-router-dom";
import { useDrawer } from "../hooks/useDrawer";
import LoadingAuthentication from "../components/authentication/LoadingAuthentication";
import NotAuthorized from "./NotAuthorized";
import ImportRequest from "./device/ImportRequest";
import Home from "./Home";

function Main() {
  const { isAuthenticated, isAuthorized } = useAuthentication();
  const { isOpen, toggleDrawer } = useDrawer();
  if (isAuthenticated === false) {
    return <Login />;
  }
  if (isAuthorized === null || isAuthenticated === "loading") {
    return <LoadingAuthentication />;
  }
  if (isAuthorized === false) {
    return <NotAuthorized />;
  } else {
    return (
      <MainPage>
        <Navbar isOpen={isOpen} toggleDrawer={toggleDrawer} />
        <MainPageContent>
          <Sidebar isOpen={isOpen} toggleDrawer={toggleDrawer} />
          <Routes>
            <Route exact path="/" element={<Home/>} />
            <Route exact path="/device/import" element={<ImportRequest/>} />
          </Routes>
        </MainPageContent>
      </MainPage>
    );
  }
}

export default Main;
