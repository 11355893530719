import { AutoAwesome, Cancel, CheckCircle, HelpCenterRounded, PublishedWithChangesRounded, WarningRounded } from '@mui/icons-material'

import React from 'react'
import Spinner from './Spinner'

function StatusIcon({status}) {
  switch(status){
    case "css":
    case true:
      return <CheckCircle style={{color:"green"}}/>
    case "wip":
      return <Spinner />
    case "new":
      return <AutoAwesome style={{color:"orangered"}}/>
    case "cwe":
    case "cer":
      return <Cancel style={{color:"red"}}/>
    case "cww":
    case "cwr":
      return <WarningRounded style={{color:"gold"}}/>
    case "unk":
      return <HelpCenterRounded style={{color:"gray"}}/>
    case "rtd":
      return <PublishedWithChangesRounded style={{color:"green"}}/>
    default:
      return <></>
  }
}

export default StatusIcon