import React from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Box,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import UserButton from "../authentication/UserButton";
import { Link } from "react-router-dom";

// Replace with your company logo
const companyLogo = require("../../images/Numiato_white.png");

function Navbar({ isOpen, toggleDrawer }) {
  return (
    <AppBar
      position="fixed"
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
    >
      <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
        {/* Left side with Hamburger Menu and Company Logo */}
        <Box display="flex" alignItems="center">
          <IconButton
            edge="start"
            color="inherit"
            onClick={toggleDrawer(!isOpen)}
            sx={{ mr: 1 }} // Hide on large screens
          >
            <MenuIcon />
          </IconButton>
          <Link to="/">
          <img
            src={companyLogo}
            alt="Company Logo"
            style={{ mx: 2, height: 25 }}
          /></Link>
          {/* <Typography variant="h6" sx={{ ml: 4 }}>
            Partner
          </Typography> */}
        </Box>

        {/* Right side for the Authenticated User Component */}
        <Box display="flex" alignItems="center">
          {/* Replace this with your user component */}
          <UserButton />
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default Navbar;
