import React from "react";
import {
  TableContainer,
  TableHead,
  TableRow,
  Table,
  TableCell,
  Paper,
  TableBody,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import StatusSelect from "../common/StatusSelect";

function ImportDeviceRequestTable({
  data,
  handleChange,
  modified,
  type = "smm",
}) {
  const { t } = useTranslation("global");

  return (
    <TableContainer component={Paper} sx={{ mb: 2 }}>
      <Table size="small">
        {/* Table Header */}
        <TableHead>
          {type === "smm" ? (
            <TableRow>
              <TableCell>{t("properties.serial_number")}</TableCell>
              <TableCell sx={{ display: { xs: "none", md: "table-cell" } }}>
                {t("properties.manufacturer")}
              </TableCell>
              <TableCell sx={{ display: { xs: "none", md: "table-cell" } }}>
                {t("properties.model")}
              </TableCell>
              <TableCell>{t("properties.group_tag")}</TableCell>
              <TableCell>{t("properties.status")}</TableCell>
            </TableRow>
          ) : (
            <TableRow>
              <TableCell>{t("properties.product_id")}</TableCell>
              <TableCell>{t("properties.group_tag")}</TableCell>
              <TableCell>{t("properties.status")}</TableCell>
            </TableRow>
          )}
        </TableHead>

        {/* Table Body */}
        <TableBody>
          {data.map((row) => (
            <TableRowComponent
              row={row}
              handleChange={handleChange}
              key={row.id}
              modified={modified}
              type={type}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
/* Row Component */
function TableRowComponent({ row, handleChange, modified, type }) {
  const handleChangeState = (state) => {
    handleChange(row.id, state); // Call parent handler with row id and new state
  };
  const currentState =
    modified.find((item) => item.id === row.id)?.state || row.state;

  return type === "smm" ? (
    <TableRow
      sx={{
        "&:last-child td, &:last-child th": { border: 0 },
      }}
    >
      <TableCell>{row.serial_number}</TableCell>
      <TableCell sx={{ display: { xs: "none", md: "table-cell" } }}>
        {row.manufacturer}
      </TableCell>
      <TableCell sx={{ display: { xs: "none", md: "table-cell" } }}>
        {row.model}
      </TableCell>
      <TableCell>{row.group_tag}</TableCell>
      <TableCell>
        <StatusSelect
          type="importDevice"
          value={currentState}
          isHighlighted={modified.find((item) => item.id === row.id)}
          onChange={handleChangeState}
        />
      </TableCell>
    </TableRow>
  ) : (
    <TableRow
      sx={{
        "&:last-child td, &:last-child th": { border: 0 },
      }}
    >
      <TableCell>{row.product_id}</TableCell>
      <TableCell>{row.group_tag}</TableCell>
      <TableCell>
        <StatusSelect
          type="importDevice"
          value={currentState}
          isHighlighted={modified.find((item) => item.id === row.id)}
          onChange={handleChangeState}
        />
      </TableCell>
    </TableRow>
  );
}
export default ImportDeviceRequestTable;
