import { initReactI18next } from "react-i18next";
import i18n from "i18next";

import global_en from "../i18n/EN.json";
import global_de from "../i18n/DE.json";
import moment from "moment";

i18n.use(initReactI18next).init({
  interpolation: { escapeValue: false },
  fallbackLng: "de",
  resources: { en: { global: global_en }, de: { global: global_de } },
});

export default i18n;

export const useI18n = ()=>{
  const changeLanguage = (language)=>{
    i18n.changeLanguage(language)
    moment.locale(language)
  }
  moment.locale(i18n.language)

  return {changeLanguage}
}