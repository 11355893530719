import { Box, Toolbar, Typography } from "@mui/material";
import React from "react";

function Page({ children }) {
  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        overflow: "scroll",
        backgroundColor: (t) =>
          t.palette.mode === "light" ? t.palette.grey[50] : t.palette.grey[900],
      }}
    >
      {children}
    </Box>
  );
}
export function PageTitle({ children }) {
  return (
    <Typography
      variant="h4"
      sx={{ justifyContent: "space-between", display: "flex", m: 2 }}
    >
      {children}
    </Typography>
  );
}
export function PageSubtitle({ children }) {
  return (
    <Typography
      variant="h6"
      sx={{
        alignContent: "center",
        alignItems: "center",
        display: "flex",
        m: 2,
      }}
    >
      {children}
    </Typography>
  );
}
export function PageContent({ children }) {
  return (
    <Box
      sx={{
        p: 2,
        display: "flex",
        flexDirection: "column",
        flex: 1,
      }}
    >
      {children}
    </Box>
  );
}

export function MainPageContent({ children }) {
  return (
    <>
      <Toolbar />
      <Box
        sx={{
          height: "100%",
          display: "flex", // Flex layout for sidebar and page content
          // flex: 1, // Allow this box to grow and take up remaining space
          overflow: "hidden", // Prevent overflow outside main content box
        }}
      >
        {children}
      </Box>
    </>
  );
}

export function MainPage({ children }) {
  return (
    <Box
      sx={{
        height: "100vh", // Full viewport height
        width: "100vw", // Full viewport width
        display: "flex", // Enable flex layout for sidebar and content
        flexDirection: "column", // Use column layout for toolbar + main content
      }}
    >
      {children}
    </Box>
  );
}

export default Page;
