import React, { useState } from "react";
import Collapsible, {
  CollapsibleBody,
  CollapsibleTitle,
} from "../layout/system/Collapsible";
import { Box, Button, Grid2, Tooltip, Typography } from "@mui/material";
import moment from "moment";
import StatusIcon from "../common/StatusIcon";
import { useTranslation } from "react-i18next";
import {
  useImportRequest,
  useImportRequestList,
} from "../../hooks/useImportRequest";
import ImportDeviceRequestTable from "./ImportDeviceRequestTable";
import RefreshButton from "../common/RefreshButton";
import LoadingBox from "../common/LoadingBox";
function ImportDeviceRequestList() {
  // const importRequestList = data;
  const { importRequestList } = useImportRequestList();

  // const {importRequestList} = useDevice()
  return (
    <Box>
      {importRequestList ? (
        importRequestList.map(
          (request) =>
            (request.mpi.length > 0 || request.smm.length > 0) && (
              <ImportRequest key={request.id} taskData={request} />
            )
        )
      ) : (
        <LoadingBox />
      )}
    </Box>
  );
}

function ImportRequest({ taskData }) {
  const { t } = useTranslation("global");
  const [modified, setModified] = useState([]);
  const { saveChanges } = useImportRequest();

  // Function to update the status in the modified array
  const handleUpdateStatus = (id, state) => {
    setModified((prev) => {
      const existingIndex = prev.findIndex((item) => item.id === id);
      if (existingIndex !== -1) {
        return prev.map((item, index) =>
          index === existingIndex ? { ...item, state } : item
        );
      } else {
        return [{ id, state }, ...prev];
      }
    });
  };

  // Function to batch update all statuses
  const handleBatchUpdateStatus = (newState) => {
    setModified((prev) => {
      // Filter out IDs that are already in the `modified` array
      const existingIds = new Set(prev.map((item) => item.id));
      const updates = [...taskData.smm, ...taskData.mpi]
        .filter((row) => !existingIds.has(row.id)) // Skip rows with existing IDs
        .map((row) => ({
          id: row.id,
          state: newState,
        }));

      // Merge the new updates with the previous state
      return [...prev, ...updates];
    });
  };

  const handleSave = () => {
    if (saveChanges(modified)) {
      setModified([]);
    }
    // saveChanges(modified).then(setModified([])).catch(console.log("error"));
  };

  return (
    <Collapsible>
      {/* Collapsible Title */}
      <CollapsibleTitle>
        <Grid2 container sx={{ display: "flex", flex: 1 }}>
          <Grid2 size={9} sx={{ display: "flex", gap: 2 }}>
            <StatusIcon status={taskData.state} />
            <Typography>{taskData.tenantName}</Typography>
          </Grid2>
          <Grid2
            size={1}
            sx={{
              display: "flex",
            }}
          >
            <Typography
              sx={{
                display: { xs: "none", md: "block" },
              }}
            >
              {t("Navbar.search_footer", {
                count: taskData.display.count,
              })}
            </Typography>
          </Grid2>
          <Grid2
            size={2}
            sx={{
              display: "flex",
              justifyContent: "end",
            }}
          >
            <Typography>
              {moment(taskData.start_time).format("DD/MM/YY HH:mm")}
            </Typography>
          </Grid2>
        </Grid2>
      </CollapsibleTitle>

      {/* Collapsible Body */}
      <CollapsibleBody>
        <Box
          sx={{
            gap: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {/* Insert Button to Change All Statuses */}
          <Box
            sx={{
              mb: 2,
              justifyContent: "end",
              display: "flex",
              width: "100%",
              gap: 2,
            }}
          >
            <RefreshButton
              refresh={() => setModified([])}
              title={t("actions.undo")}
            />
            <Button
              onClick={handleSave}
              variant="contained"
              disabled={modified.length <= 0}
            >
              {t("actions.save")}
            </Button>
            <Tooltip title={t("tooltip.actions.all_success")}>
              <Button
                onClick={() => handleBatchUpdateStatus("css")}
                variant="contained"
              >
                {t("actions.all_success")}
              </Button>
            </Tooltip>
          </Box>
          {taskData.smm.length > 0 && (
            <ImportDeviceRequestTable
              data={taskData.smm}
              handleChange={handleUpdateStatus}
              modified={modified}
              type="smm"
            />
          )}
          {taskData.mpi.length > 0 && (
            <ImportDeviceRequestTable
              data={taskData.mpi}
              handleChange={handleUpdateStatus}
              modified={modified}
              type="mpi"
            />
          )}
        </Box>
      </CollapsibleBody>
    </Collapsible>
  );
}
export default ImportDeviceRequestList;
