import React, { useContext } from "react";
import { PartnerContext } from "../../tools/PartnerContext";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";

function RefreshButton({ refresh,title }) {
  const { makeRefresh } = useContext(PartnerContext);
  const { t } = useTranslation("global");
  return (
    <Button
      variant="contained"
      onClick={() => {
        makeRefresh();
        refresh && refresh();
      }}
    >
      {title ? title : t("words.refresh")}
    </Button>
  );
}

export default RefreshButton;
