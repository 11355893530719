import { ExpandMore } from "@mui/icons-material";
import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import React from "react";

function Collapsible({ children, ...props }) {
  return <Accordion {...props}>{children}</Accordion>;
}

export function CollapsibleTitle({ children, ...props }) {
  return (
    <AccordionSummary {...props} expandIcon={<ExpandMore />}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        {children}
      </Box>
    </AccordionSummary>
  );
}

export function CollapsibleBody({ children, ...props }) {
  return <AccordionDetails {...props}>{children}</AccordionDetails>;
}

export default Collapsible;
