import { Box, Typography } from '@mui/material'
import React from 'react'
import { SpinnerDiamond } from '../layout/system/Loading'
import { useTranslation } from 'react-i18next'

function LoadingAuthentication() {
  const {t} = useTranslation("global")
  return (
    <Box sx={{width:"100vw", height:"100vh", justifyContent:"center", alignItems:"center",display:"flex",gap:"30px",flexDirection:"column"}}>
      
      <SpinnerDiamond size={150} thickness={100} speed={100} color="rgba(255, 112, 67, 1)" secondaryColor="rgba(0, 0, 0, 0.44)" />
      <Typography variant='h4'>{t("words.loading")}</Typography>
    </Box>
  )
}

export default LoadingAuthentication