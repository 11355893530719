import React, { useContext } from "react";
import { Login } from "@microsoft/mgt-react";
import { Providers } from "@microsoft/mgt-element";
import { Box, Button,useMediaQuery } from "@mui/material";
import "./UserButton.css";
import { PartnerContext } from "../../tools/PartnerContext";
import { useI18n } from "../../hooks/useI18n";
function UserButton() {
  const { setAuthenticated } = useContext(PartnerContext);
  const isLargeScreen = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  
  const LoginCommandsTemplate = (props) => {
    const { changeLanguage } = useI18n();
    const handleSignOut = () => {
      sessionStorage.clear();
      setAuthenticated(false);
      Providers.globalProvider.logout();
    };
    return (
      <Box>
        <Button
          variant="text"
          fullWidth={true}
          onClick={handleSignOut}
          color="orange"
        >
          Sign Out
        </Button>
        <Box sx={{ display: "flex" }}>
          <Button
            variant="text"
            sx={{ px: 4 }}
            fullWidth={true}
            onClick={() => changeLanguage("de")}
          >
            Deutsch{" "}
          </Button>
          <Button
            variant="text"
            sx={{ px: 4 }}
            fullWidth={true}
            onClick={() => changeLanguage("en")}
          >
            English
          </Button>
        </Box>
      </Box>
    );
  };
  const Empty = () => {
    return <></>;
  };
  return (
    <div className="relative">
      <Login className="login" loginView={isLargeScreen ? "full" : "avatar"}>
        <LoginCommandsTemplate template="flyout-commands"></LoginCommandsTemplate>
        <Empty template="flyout-person-details"></Empty>
      </Login>
    </div>
  );
}

export default UserButton;
